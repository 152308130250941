.App {
  font-family: sans-serif;
  text-align: center;
}
.App-input {
  font-size: 1.5em;
}
.App-score {
  margin: 0.8em;
  font-size: 3em;
  min-width: 3em;
}
